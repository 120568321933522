import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import Layout from '../components/layout';

/**
 * About page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Err404 location={string} />
 */

const Err404 = ({ location }) => (
  <Layout pageTitle="404 - Page Not Found" location={location}>
    <article
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.mt60,
        main.mb60,
        main.postLegal,
        main.postSingle,
        main.px60
      )}
    >
      <h1 className={cx(base.mb5, base.tc, theme.colorDark)}>
        404 - Page Not Found
      </h1>
      <section>
        <p>
          Looks like the page you&apos;re looking for doesn&apos;t exist. Click
          on the links on the navbar to visit another page.
        </p>
      </section>
    </article>
  </Layout>
);

Err404.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Err404;
